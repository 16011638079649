import styled from '@emotion/styled'

import { css } from '@emotion/react'
import { Flex } from 'driverama-core/components/Flex'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, shadow, zIndex } from 'driverama-core/styles/variables'

export const SContainer = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  max-width: 100vw;
  height: ${size(18)};

  padding: ${size(2)} ${size(40)};
  background-color: ${color('white')};
  box-shadow: ${shadow(3)};
  z-index: ${zIndex('order-1')};

  @media ${media.gt('tablet')} {
    display: none;
  }

  @media ${media.lte('MD')} {
    padding: ${size(2)} ${size(10)};
  }

  @media ${media.lte('SM')} {
    padding: ${size(2)} ${size(4)};
  }
`

export const SInnerContent = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${size(12)};
  font-size: 14px;

  svg {
    height: ${size(6)};
    width: 100%;
  }

  span {
    font-size: 11px;
    line-height: 16px;
    margin-top: ${size(1)};
  }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        span,
        svg {
          color: ${color('night-d-100')};
        }
      `
    }

    return `
      
      svg {
        color: ${color('night-l-200')};
      };
    `
  }};
`
