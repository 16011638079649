import { useTranslation } from 'react-i18next'

import { LINKS } from 'constants/links'

import IconClipboard from 'images/icons/IconClipboard.svg'
import ManagerIcon from 'images/icons/IconManager.svg'
import IconMarketingSearch from 'images/icons/IconMarketingSearch.svg'
import IconRepairs from 'images/icons/IconRepairs.svg'

import { useRouter } from 'next/router'
import { SContainer } from './BottomMenu.styled'
import { BottomMenuItem } from './BottomMenuItem'

export function BottomMenu() {
  const { t } = useTranslation(['core'])
  const router = useRouter()

  const isLoginPage = router.pathname === LINKS.login

  if (isLoginPage) {
    return null
  }

  return (
    <SContainer variant="row" align="center" justify={['between', 'around']}>
      {driverama.flags.STOK_915_SERVICE_MODULE_ENABLED && (
        <BottomMenuItem
          label={t('core:header_repair_link')}
          href={LINKS.repairs}
          Icon={<IconRepairs />}
        />
      )}

      <BottomMenuItem
        href={LINKS.home}
        label={t('core:header_home_link')}
        Icon={<ManagerIcon />}
      />

      <BottomMenuItem
        href={LINKS.marketing}
        label={t('core:header_marketing_link')}
        Icon={<IconMarketingSearch />}
      />

      <BottomMenuItem
        href={LINKS.orders}
        label={t('core:header_orders_link')}
        Icon={<IconClipboard />}
      />
    </SContainer>
  )
}
