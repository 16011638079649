import Link from 'next/link'

import { TextBody } from 'driverama-core/components/text/Text'
import { SInnerContent } from './BottomMenu.styled'
import { useRouter } from 'next/router'

type ItemProps = {
  label: string
  href: string
  Icon?: JSX.Element
}

export function BottomMenuItem({ Icon, label, href }: ItemProps) {
  const { pathname } = useRouter()

  return (
    <Link href={href} passHref>
      <a>
        <InnerContent Icon={Icon} label={label} isActive={pathname === href} />
      </a>
    </Link>
  )
}

function InnerContent(
  props: Pick<ItemProps, 'Icon' | 'label'> & { isActive: boolean }
) {
  return (
    <SInnerContent isActive={props.isActive}>
      {props.Icon}
      <TextBody size="small" variant="setup">
        {props.label}
      </TextBody>
    </SInnerContent>
  )
}
