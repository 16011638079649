import { css } from '@emotion/react'
import { BottomMenu } from 'components/bottomMenu/BottomMenu'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { size } from 'driverama-core/styles/spacing'
import { useSession } from 'driverama-core/utils/auth'
import { forwardRef, ReactNode } from 'react'
import { SContainer } from './Layout.styled'

type Props = {
  header: ReactNode | undefined
  children?: ReactNode
  isLoading?: boolean
}

export const Layout = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { header, children, isLoading } = props

  const { session } = useSession()

  return (
    <SContainer>
      {header}
      <div>
        {isLoading ? (
          <SpinnerCentered
            css={css`
              padding-top: ${size(40)};
              padding-bottom: ${size(40)};
            `}
          />
        ) : (
          children
        )}
      </div>

      {session?.access_token && <BottomMenu />}
    </SContainer>
  )
})
