import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'

export const SContainer = styled.div`
  --header-height: ${size(19)};
  --table-overview: ${size(14)};
  --margin-vertical: ${size(8)};
  --mobile-bottom-menu-height: ${size(18)};
  --mobile-search-height: ${size(24)};
  --marketing-bulk-actions-height: ${size(20)};
  --car-table-header: ${size(19)};

  @media ${media.lte('tablet')} {
    --marketing-bulk-actions-height: ${size(17)};
    --car-table-header: ${size(18)};
  }
`
